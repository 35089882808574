/**
 * @author Vahagn Lazyan.
 */
import {Input} from '@angular/core';

import {
    CommunicationData,
    CommunicationKey,
    CommunicationService,
} from '@synisys/idm-dynamic-controls-metadata';
import {TooltipSettings} from './sis-tooltip-settings.component';

/**
 * @deprecated - use BaseSettings from ng-controls
 */
export abstract class SisControlWithTooltipSettings {
    protected _tooltipSettings: TooltipSettings;

    public get tooltipSettings(): TooltipSettings {
        return this._tooltipSettings;
    }

    @Input()
    public set tooltipSettings(settings: TooltipSettings) {
        this._tooltipSettings = settings;
        this._communicationService.parentSubject.next(
            new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
                state: {
                    tooltipSettings: this._tooltipSettings.toJsonString(),
                },
            })
        );
    }

    protected constructor(
        protected _communicationService: CommunicationService
    ) {}
}
