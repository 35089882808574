/**
 * @author Vahagn Lazyan.
 * @since 2.2.0
 */
import {AfterContentInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';

import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';

import {WarningPopupComponent} from './warning-popup';
import './warning-button.component.scss';

/**
 * Button with warning message popup.
 * By default this is a delete button, so default message keys are in context of deletion.
 * @implements AfterContentInit
 */
@Component({
             moduleId   : module.id + '',
             selector   : 'sis-warning-button',
             templateUrl: 'warning-button.component.html',
           })
@ControlMetadata({
                   template: `<sis-warning-button
                                    [id]="'%{id}'"
                                    [classes]="%{class}"
                                    (okActionDone)="%{okAction}"
                                    %{#title_msg} [popupTitleKey]="'%{title_msg}'" %{/title_msg}
                                    %{#warning_msg} [warningMessageKey]="'%{warning_msg}'" %{/warning_msg}
                                    %{#button_msg} [buttonMessageKey]="'%{button_msg}'" %{/button_msg}>
                              </sis-warning-button>`,
                 })
export class WarningButtonComponent implements AfterContentInit {

  @Input()
  public id: string;

  /**
   * Additional classes to pass from outer.
   * @default
   * @type {string}
   */
  @Input()
  public classes = '';

  /**
   * Title message key for {@link WarningPopupComponent}.
   * @default
   * @type {string}
   */
  @Input()
  public popupTitleKey = 'de_delete_popup_title';

  /**
   * Warning message key.
   * @default
   * @type {string}
   */
  @Input()
  public warningMessageKey = 'de_delete_warning';

  /**
   * Button message key.
   * @default
   * @type {string}
   */
  @Input()
  public buttonMessageKey = 'de_delete';

  @Output()
  public okActionDone: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public noActionDone: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Warning Popup component.
   */
  @ViewChild('warningPopup')
  private warningPopup: WarningPopupComponent;

  private _isReady: boolean;
  /**
   * Warning Popup Active Status
   * @type boolean
   */
  private _isWarningPopupActive = false;

  get isReady(): boolean {
    return this._isReady;
  }

  get isWarningPopupActive(): boolean {
    return this._isWarningPopupActive;
  }

  public ngAfterContentInit(): void {
    this._isReady = true;
  }

  public okAction() {
    this.okActionDone.emit();
    this.setWarningPopupActive(false);
  }

  public noAction() {
    this.noActionDone.emit();
    this.setWarningPopupActive(false);
  }

  public openWarningPopup() {
    this.setWarningPopupActive(true);
  }

  public setWarningPopupActive(isWarningPopupActive: boolean): void {
    if (this.isWarningPopupActive !== isWarningPopupActive) {
      this._isWarningPopupActive = isWarningPopupActive;
      this.warningPopup && isWarningPopupActive && this.warningPopup.openPopup();
      this.warningPopup && (!isWarningPopupActive) && this.warningPopup.closePopup();
    }
  }
}
