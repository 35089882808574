import {MetaField} from "@synisys/idm-kb-service-client-js";
import {MultilingualString} from "@synisys/idm-crosscutting-concepts-frontend";

export class DraggableColumnModel {
  public readonly meta: MetaField;
  public readonly id:string;
  public readonly metaFieldId;
  public readonly displayNameMsgId;
  public readonly type;
  public readonly isMultiline;
  public readonly compoundCategorySystemName;
  public readonly maxLength;
  public readonly fieldPrefix;
  public readonly systemName;
  public cellCount: number = 3;
  public disabled = false;

  public checkedInLayout = false;

  public fieldMultilingualDisplayName: {[key: number]: string};

  constructor(meta: MetaField) {
    this.metaFieldId = meta.getMetaFieldId();
    this.systemName = meta.getMetaFieldId().getSystemName();
    this.displayNameMsgId = meta.getDisplayNameMsgId();
    this.type = meta.getType();
    this.isMultiline = meta.getIsMultiline();
    this.compoundCategorySystemName = meta.getCompoundCategorySystemName();
    this.maxLength = meta.getMaxLength();
    this.fieldPrefix = meta.getFieldPrefix();
    this.id = this.uniqueId();
    if(meta.getDisplayNameMultilingual()){
      this.fieldMultilingualDisplayName = this.multiLingualToJson(meta.getDisplayNameMultilingual());
    }
  }


  private  uniqueId(): string {
    return 'xxxxxxxx'.replace(/[xy]/g, function (c) {
      // tslint:disable-next-line:no-bitwise
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }


    private  multiLingualToJson(
        multiString: MultilingualString
    ): {[key: number]: string} {
        const obj: {[key: number]: string} = {};
        multiString
            .toMap()
            .forEach((value: string, key: number) => (obj[key] = value));
        return obj;
    }
}
