import {map} from 'rxjs/operators';
import {Component, Input, OnInit} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {
    Language,
    MultilingualString,
} from '@synisys/idm-crosscutting-concepts-frontend';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {TooltipSettingsComponent} from './settings';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import './sis-tooltip.component.scss';

@Component({
    moduleId: module.id + '',
    selector: 'sis-tooltip',
    templateUrl: 'sis-tooltip.component.html',
})
@ControlMetadata({
    name: 'Tooltip',
    nameKey: 'de.controls.tooltip.title',
    cellCount: 1,
    settings: {
        main: TooltipSettingsComponent,
    },
    template: `<sis-tooltip
                [isReadonly]="%{isReadonly}"
                [tooltipText]="%{tooltipText}"
                [tooltipTitle]="%{tooltipTitle}">
               </sis-tooltip>`,
})
export class TooltipComponent implements OnInit {
    @Input()
    public isReadonly: boolean;

    @Input()
    public tooltipText: string;

    @Input()
    public tooltipTitle: string;

    public multilingualTooltipTitle$: Observable<string> = of('');

    public multilingualTooltipText$: Observable<string> = of('');

    constructor(private currentLanguageProvider: CurrentLanguageProvider) {}

    public ngOnInit(): void {
        this.identifyCurrentMessage();
    }

    public getCurrentMessage(
        currentLanguageId: number,
        text: string | MultilingualString
    ): string {
        if (text && currentLanguageId) {
            if (text instanceof MultilingualString) {
                return text.getValue(currentLanguageId);
            } else {
                return JSON.parse(text)._values
                    ? JSON.parse(text)._values.find(
                          (item: [number, string]) =>
                              item[0] === currentLanguageId
                      )[1]
                    : text;
            }
        }
        return '';
    }

    private identifyCurrentMessage(): void {
        this.multilingualTooltipText$ = this.currentLanguageProvider
            .getCurrentLanguage()
            .pipe(
                map((language: Language) => {
                    return this.getCurrentMessage(
                        language.getId(),
                        this.tooltipText
                    );
                })
            );

        this.multilingualTooltipTitle$ = this.currentLanguageProvider
            .getCurrentLanguage()
            .pipe(
                map((language: Language) => {
                    return this.getCurrentMessage(
                        language.getId(),
                        this.tooltipTitle
                    );
                })
            );
    }
}
