import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ControlsModule} from '@synisys/idm-ng-controls';
import {DeControlsModule} from '@synisys/idm-de-service-client-js';
import {MaterialModule} from './material.module';
import {
    BannerMobileComponent,
    CancelButtonComponent,
    CancelWarningPopupComponent,
    DeleteConfirmDialogComponent,
    DynamicFilterComponent,
    DynamicPortfolioComponent,
    DynamicQuickFilterComponent,
    EntityFieldViewerComponent,
    EntityFieldViewerSettingsComponent,
    ExportDataLoaderService,
    HeaderComponent,
    HeaderExportButtonComponent,
    HeaderInformationGroupComponent,
    HeaderMobileComponent,
    HeaderSettingsComponent,
    HeaderThreeDotMenuComponent,
    HierarchicalDrillDownComponent,
    HierarchicalSearchComponent,
    HierarchicalSearchService,
    HierarchicalSelectComponent,
    HierarchicalSelectSettingsComponent,
    InlineSisTableComponent,
    InlineSisTableSettingsComponent,
    MobileCancelButtonComponent,
    SaveCancelButtonGroupComponent,
    SisAccountingSettingsComponent,
    SisBooleanRadioComponent,
    SisBooleanRadioSettingsComponent,
    SisButtonComponent,
    SisCaptionComponent,
    SisCheckboxComponent,
    SisCheckboxSettingsComponent,
    SisControlLabelSettingsComponent,
    SisDateTimePickerComponent,
    SisDateTimeSettingsComponent,
    SisDatetimeViewComponent,
    SisDecimalComponent,
    SisDecimalSettingsComponent,
    SisDocComponent,
    SisDocumentDownloadComponent,
    SisDocumentSettingsComponent,
    SisDynamicAccountingComponent,
    SisDynamicChipGroupComponent,
    SisDynamicCurrencyModule,
    SisDynamicDocumentComponent,
    SisDynamicMoneyComponent,
    SisDynamicMultiselectComponent,
    SisDynamicMultiSelectSettingsComponent,
    SisHeaderViewFormComponent,
    SisHintSettingsComponent,
    SisInlineTableComboBoxComponent,
    SisInlineTableMainEntityComponent,
    SisIntegerComponent,
    SisIntegerSettingsComponent,
    SisMoneySettingsComponent,
    SisNumberComponent,
    SisNumberSettingsComponent,
    SisPipeMsgComponent,
    SisRadioComponent,
    SisRadioSettingsComponent,
    SisSimpleCombo,
    SisSimpleComboSettingsComponent,
    SisSimpleTextComponent,
    SisSimpleVaryingTextComponent,
    SisSingleCheckboxComponent,
    SisSingleCheckboxSettingsComponent,
    SisTable,
    SisTableBuilder,
    SisTableSettings,
    SisTextAreaComponent,
    SisTextAreaSettingsComponent,
    SisTextBoxComponent,
    SisTextBoxSettingsComponent,
    SisUserComponent,
    SisWfButtonComponent,
    SubwayMapComponent,
    SubwayMapModule,
    TooltipComponent,
    TooltipSettingsComponent,
    WarningButtonComponent,
    WarningPopupComponent,
    SisDynamicEntitySelectComponent,
} from './controls/index';
import {
    DynamicCommunicationService,
    ExtractionService,
    FormattingService,
    DefaultExportDataLoaderService,
} from './services/index';
import {
    FormResolverService,
    SisTranslateModule,
} from '@synisys/idm-frontend-shared';
import {DocumentControlsModule} from '@synisys/idm-document-service-client-js';
import {
    EntityNamePipe,
    SisTranslateMultilingualPipe,
    SisValidationMessagePipe,
    TranslateMessageToMultilingualString,
} from './pipes';
import {DateAdapter} from '@angular/material';
import {EntitySearchModule} from '@synisys/idm-entity-search-client-js';
import {DragulaModule} from 'ng2-dragula';
import {
    SisAccountingComponent,
    SisMoneyComponent,
} from '@synisys/idm-currency-rate-frontend';
import {CustomDateAdapter} from '@synisys/idm-ng-controls/app/controls/date-picker/custom-date-adapter';
import {ExportTemplatesServiceModule} from '@synisys/idm-export-templates-service-client-js';
import {HelperDirective} from './controls/helper.directive';
import {ExportCopyTemplateDialogComponent} from './controls/header-export-button/export-copy-template/export-copy-template-dialog.component';

@NgModule({
    declarations: [
        BannerMobileComponent,
        InlineSisTableComponent,
        InlineSisTableSettingsComponent,
        SisTable,
        DeleteConfirmDialogComponent,
        DynamicPortfolioComponent,
        DynamicFilterComponent,
        DynamicQuickFilterComponent,
        HeaderMobileComponent,
        HeaderThreeDotMenuComponent,
        HierarchicalDrillDownComponent,
        HierarchicalSearchComponent,
        HierarchicalSelectComponent,
        HierarchicalSelectSettingsComponent,
        TooltipComponent,
        TooltipSettingsComponent,
        SisDocComponent,
        SisInlineTableComboBoxComponent,
        SisInlineTableMainEntityComponent,
        SisSimpleCombo,
        SisSimpleTextComponent,
        SisSimpleVaryingTextComponent,
        SisSingleCheckboxComponent,
        SisPipeMsgComponent,
        SisCaptionComponent,
        SisButtonComponent,
        SisBooleanRadioComponent,
        SisCheckboxComponent,
        SisRadioComponent,
        SisTextAreaComponent,
        SisTextBoxComponent,
        SisWfButtonComponent,
        SisDatetimeViewComponent,
        WarningButtonComponent,
        WarningPopupComponent,
        CancelButtonComponent,
        CancelWarningPopupComponent,
        SisDecimalComponent,
        SisIntegerComponent,
        SisUserComponent,
        SisDateTimePickerComponent,
        SisRadioSettingsComponent,
        SaveCancelButtonGroupComponent,
        SisHeaderViewFormComponent,
        HeaderInformationGroupComponent,
        SisValidationMessagePipe,
        SisDynamicMultiselectComponent,
        EntityNamePipe,
        SisDynamicMultiselectComponent,
        HeaderComponent,
        HeaderExportButtonComponent,
        HeaderSettingsComponent,
        EntityFieldViewerComponent,
        EntityFieldViewerSettingsComponent,
        SisNumberComponent,
        SisNumberSettingsComponent,
        SisTranslateMultilingualPipe,
        SisHintSettingsComponent,
        SisCheckboxSettingsComponent,
        SisBooleanRadioSettingsComponent,
        SisDateTimeSettingsComponent,
        SisDecimalSettingsComponent,
        SisDynamicMultiSelectSettingsComponent,
        SisIntegerSettingsComponent,
        SisSimpleComboSettingsComponent,
        SisSingleCheckboxSettingsComponent,
        SisTextBoxSettingsComponent,
        SisTextAreaSettingsComponent,
        SisDocumentDownloadComponent,
        MobileCancelButtonComponent,
        TranslateMessageToMultilingualString,
        SisTableBuilder,
        SisTableSettings,
        SisControlLabelSettingsComponent,
        SisDynamicDocumentComponent,
        SisDocumentSettingsComponent,
        SisDynamicChipGroupComponent,
        HelperDirective,
        ExportCopyTemplateDialogComponent,
        SisDynamicEntitySelectComponent,
    ],
    entryComponents: [
        DeleteConfirmDialogComponent,
        CancelWarningPopupComponent,
        ExportCopyTemplateDialogComponent,
    ],
    exports: [
        BannerMobileComponent,
        InlineSisTableComponent,
        InlineSisTableSettingsComponent,
        SisTable,
        SisTableBuilder,
        SisTableSettings,
        DynamicPortfolioComponent,
        DynamicFilterComponent,
        DynamicQuickFilterComponent,
        HeaderMobileComponent,
        HeaderThreeDotMenuComponent,
        HierarchicalSelectComponent,
        HierarchicalDrillDownComponent,
        HierarchicalSelectSettingsComponent,
        TooltipComponent,
        TooltipSettingsComponent,
        SisDocComponent,
        SisDocComponent,
        SisSimpleCombo,
        SisSimpleTextComponent,
        SisSimpleVaryingTextComponent,
        SisSingleCheckboxComponent,
        SisPipeMsgComponent,
        SisCaptionComponent,
        SisButtonComponent,
        SisBooleanRadioComponent,
        SisCheckboxComponent,
        SisRadioComponent,
        SisTextAreaComponent,
        SisTextBoxComponent,
        SisWfButtonComponent,
        SisDatetimeViewComponent,
        WarningButtonComponent,
        WarningPopupComponent,
        CancelButtonComponent,
        CancelWarningPopupComponent,
        SisDecimalComponent,
        SisIntegerComponent,
        SisUserComponent,
        SisDateTimePickerComponent,
        SisRadioSettingsComponent,
        SaveCancelButtonGroupComponent,
        SisHeaderViewFormComponent,
        HeaderInformationGroupComponent,
        SisDynamicMultiselectComponent,
        SisDynamicMultiselectComponent,
        SisValidationMessagePipe,
        EntityNamePipe,
        HeaderComponent,
        HeaderExportButtonComponent,
        HeaderSettingsComponent,
        EntityFieldViewerComponent,
        EntityFieldViewerSettingsComponent,
        SisNumberComponent,
        SisNumberSettingsComponent,
        SisTranslateMultilingualPipe,
        SisHintSettingsComponent,
        SisCheckboxSettingsComponent,
        SisBooleanRadioSettingsComponent,
        SisDateTimeSettingsComponent,
        SisDecimalSettingsComponent,
        SisDynamicMultiSelectSettingsComponent,
        SisIntegerSettingsComponent,
        SisSimpleComboSettingsComponent,
        SisSingleCheckboxSettingsComponent,
        SisTextBoxSettingsComponent,
        SisTextAreaSettingsComponent,
        SisDocumentDownloadComponent,
        MobileCancelButtonComponent,
        TranslateMessageToMultilingualString,
        SisControlLabelSettingsComponent,
        SisAccountingComponent,
        SisMoneyComponent,
        SisDynamicMoneyComponent,
        SisMoneySettingsComponent,
        SisDynamicAccountingComponent,
        SisAccountingSettingsComponent,
        SisDynamicDocumentComponent,
        SisDocumentSettingsComponent,
        SubwayMapComponent,
        SisDynamicChipGroupComponent,
        SisDynamicEntitySelectComponent,
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        ControlsModule,
        CommonModule,
        DeControlsModule,
        MaterialModule,
        DocumentControlsModule,
        EntitySearchModule,
        DragulaModule,
        SisDynamicCurrencyModule,
        SubwayMapModule,
        SisTranslateModule,
        ExportTemplatesServiceModule,
    ],
    providers: [
        {
            provide: DynamicCommunicationService,
            useFactory: DynamicCommunicationService.createInstance,
        },
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter,
        },
        {
            provide: ExportDataLoaderService,
            useClass: DefaultExportDataLoaderService,
        },
        FormResolverService,
        ExtractionService,
        HierarchicalSearchService,
        FormattingService,
    ],
})
export class DynamicFormControlsModule {}
