import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {Logger} from '@synisys/skynet-store-utilities';

export function fullParse(json: string): any {
    try {
        const parsed = JSON.parse(json);
        return fullParse(parsed);
    } catch (error) {
        return json;
    }
}

export function multilingualToString(
    multilingualString: MultilingualString | undefined
): string {
    if (!multilingualString) {
        return '';
    }
    const obj: object = {};
    multilingualString.toMap().forEach((value: string, key: number) => {
        obj[key] = value;
    });

    return JSON.stringify(obj);
}

export function stringToMultilingual(
    json: string
): MultilingualString | undefined {
    if (json === '') {
        return undefined;
    }
    const multilingualString = JSON.parse(json);
    const builder = MultilingualString.newBuilder();
    for (const key in multilingualString) {
        if (multilingualString.hasOwnProperty(key)) {
            builder.withValueForLanguage(+key, multilingualString[key]);
        }
    }
    return builder.build();
}

// Decorator
// tslint:disable:no-any
// tslint:disable:no-invalid-this
export function CoolDown(ms: number) {
    let enabled = true;
    return function(
        target: any,
        propertyKey: string,
        descriptor: PropertyDescriptor
    ) {
        const method = descriptor.value;
        descriptor.value = function(...args: any[]) {
            if (enabled) {
                enabled = false;
                setTimeout(() => (enabled = true), ms);
                return method.apply(this, args);
            }
        };
    };
}

export function createLogger(scope: string): Logger {
    return new Logger('idm-dynamic-forms-controls', scope);
}
