import {AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DragulaService} from 'ng2-dragula';
import {
    CommunicationData,
    CommunicationKey,
    CommunicationService,
    ControlMetadata,
} from '@synisys/idm-dynamic-controls-metadata';
import {KbService, MetaField} from '@synisys/idm-kb-service-client-js';
import {DraggableColumnModel} from './draggable-column.model';
import {AbstractDestructionSubject} from '../abstract-destruction-subject';
import {filter, takeUntil} from 'rxjs/operators';
import './sis-table-builder.component.scss';

@Component({
               moduleId   : module.id + '',
               selector   : 'sis-table-builder',
               templateUrl: 'sis-table-builder.component.html',
           })
@ControlMetadata({
                     template: `<sis-table-builder [category]="%{category}" [dragulaService]="dragulaService"
                                                [currentLanguageId]="%{currentLanguageId}"
                                                 [fields]="%{fields}" [state]="%{state}">
                              </sis-table-builder>`,
                 })
export class SisTableBuilder extends AbstractDestructionSubject implements OnInit, OnDestroy, AfterViewInit {

    @Input()
    public category: string;

    @Input()
    public fields: { [s: string]: MetaField; } = {};

    @Input()
    public dragulaService: DragulaService;

    @Input()
    public state: { [s: string]: object; } = {};

    @Input()
    public currentLanguageId: number;

    public tableDragulaOptions: object;
    public colFields: DraggableColumnModel[] = [];
    public scrollModels: DraggableColumnModel[] = [];
    public resizing = false;
    public resizedCell: DraggableColumnModel;
    public containerWidth: number;
    public xBeforeResizing: number;

    @ViewChild('tableContainer', {read: ElementRef})
    public tableContainer: ElementRef;
    private documentIsRightToLeft: boolean;


    private scrollIntervalId: NodeJS.Timer;

    constructor(private readonly communicationService: CommunicationService,
                private readonly kbService: KbService) {
        super();
    }

    public ngOnInit(): void {
        this.initDragula();
        this.prepareColumns();

        this.communicationService.peerSubject.pipe(
            filter(data => data.key === CommunicationKey.SETTINGS_CHANGED),
            takeUntil(this.destroySubject$),
        ).subscribe(() => {
            this.colFields = [];
        }, console.error);
    }

    public isEmpty() {
        return this.colFields.length === 0;
    }

    public deleteColumn(id: string) {
        this.colFields.splice(this.colFields.findIndex(col => col.id == id), 1);
        this.communicationService.parentSubject.next(new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
            state: {
                'colSystemNames': this.colFields.map(field => field.systemName),
            },
        }));

        this.communicationService.peerSubject.next(new CommunicationData(CommunicationKey.PREVIEW, this.colFields));
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this.dragulaService) {
            this.dragulaService.destroy('table-items');
        }
    }

    public ngAfterViewInit(): void {
        this.documentIsRightToLeft = (document.dir === 'rtl');
    }

    @HostListener('document:mousemove', ['$event'])
    public resizeEvent(event: any) {
        if (this.resizing && this.resizedCell) {
            const colSize: number = this.containerWidth / 12;
            let diff: number;

            diff = (event.clientX - this.xBeforeResizing);

            const columns = diff / colSize;

            if (this.documentIsRightToLeft) {
                if (this.resizedCell.cellCount - columns >= 1) {
                    this.resizedCell.cellCount -= columns;
                } else {
                    this.resizedCell.cellCount = 1;
                }
            } else {
                this.resizedCell.cellCount += columns;
            }
        }
        this.xBeforeResizing = event.clientX;
    }

    @HostListener('document:mouseup', ['$event'])
    public releaseResize(event: MouseEvent) {
        this.resizing = false;
        if (this.resizedCell) {
            const expectedSize: number = Math.round(this.resizedCell.cellCount);
            if (expectedSize >= 1) {
                this.resizedCell.cellCount = expectedSize;
            } else {
                this.resizedCell.cellCount = 1;
            }
        }
        this.resizedCell = null;
        this.communicationService.parentSubject.next(new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
            state: {
                'cellCounts': this.colFields.map(field => field.cellCount),
            },
        }));
    }

    public getColumnSize(cell: DraggableColumnModel): number {
        if (cell && cell.cellCount) {
            let size = cell.cellCount / 12 * 100;

            if (size <= 0) {
                size = 1;
            } else if (size >= 100) {
                size = 100;
            }

            return size;
        }
    }

    public resizeClickEvent(event: any, cell: DraggableColumnModel) {
        this.containerWidth = this.tableContainer.nativeElement.clientWidth;

        this.resizing = true;
        this.resizedCell = cell;

        this.xBeforeResizing = event.clientX;
        event.preventDefault();
        event.stopPropagation();
    }

    public scrollRight() {
        if (!this.scrollIntervalId) {
            this.scrollIntervalId =
                setInterval(() => this.tableContainer.nativeElement.scrollBy(2, -0), 1);
        }
    }

    public scrollLeft() {
        if (!this.scrollIntervalId) {
            this.scrollIntervalId =
                setInterval(() => this.tableContainer.nativeElement.scrollBy(-2, 0), 1);
        }
    }

    public stopScrolling() {
        if (this.scrollIntervalId) {
            clearInterval(this.scrollIntervalId);
            this.scrollIntervalId = undefined;
        }
    }

    public trackByFunc(index: number, columnField: DraggableColumnModel) {
        return columnField.id;
    }

    private prepareColumns() {
        if (this.fields.field && this.state.colSystemNames) {
            const tableField: MetaField = this.fields.field;
            const cols: string[] = this.state.colSystemNames as string[];
            const cellCounts: number[] = this.state.cellCounts as number[];
            this.kbService.getMetaFields(tableField.getCompoundCategorySystemName())
                .pipe(takeUntil(this.destroySubject$))
                .subscribe((metaFields: MetaField[]) => {
                    cols.map((col: string, index: number) => {
                        const draggableColumnModel = new DraggableColumnModel
                        (metaFields.find(meta => meta.getSystemName() === col));
                        if (cellCounts && cellCounts.length > index) {
                            draggableColumnModel.cellCount = cellCounts[index];
                        }
                        this.colFields.push(draggableColumnModel);
                    });

                }, console.error);
        }
    }

    private initDragula() {
        this.dragulaService.dropModel.pipe(
            filter((value: [string, HTMLElement, HTMLElement, HTMLElement]) => {
                return value[0] === 'table-items';
            }),
            takeUntil(this.destroySubject$),
        ).subscribe(
            (value: [string, HTMLElement, HTMLElement, HTMLElement]) => {
                const elem = value[1];
                if (elem.id === 'new_field') {
                    const draggedColumn = this.colFields.find(item => !item.checkedInLayout);
                    if (draggedColumn) {
                        draggedColumn.checkedInLayout = true;
                    }
                }
                this.communicationService.parentSubject.next(new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
                    state: {
                        'colSystemNames': this.colFields.map(field => field.systemName),
                        'cellCounts'    : this.colFields.map(field => field.cellCount),
                    },
                }));

                this.communicationService.peerSubject.next(
                    new CommunicationData(CommunicationKey.PREVIEW, this.colFields));
            }, console.error);

        this.dragulaService.over.pipe(
            filter((value: [string, HTMLElement, HTMLElement, HTMLElement]) => {
                return value[0] === 'table-items';
            }),
            takeUntil(this.destroySubject$),
        ).subscribe(
            (value: [string, HTMLElement, HTMLElement, HTMLElement]) => {
                const container = value[2];

                if (container.id === 'left_scroll') {
                    this.scrollLeft();
                }

                if (container.id === 'right_scroll') {
                    this.scrollRight();
                }

            }, console.error);

        this.dragulaService.drag.pipe(
            filter((value: [string, HTMLElement, HTMLElement, HTMLElement]) => {
                return value[0] === 'table-items';
            }),
            takeUntil(this.destroySubject$),
        ).subscribe(
            (value: [string, HTMLElement, HTMLElement, HTMLElement]) => {
            }, console.error);

        this.dragulaService.out.pipe(
            filter((value: [string, HTMLElement, HTMLElement, HTMLElement]) => {
                return value[0] === 'table-items';
            }),
            takeUntil(this.destroySubject$),
        ).subscribe(
            (value: [string, HTMLElement, HTMLElement, HTMLElement]) => {
                this.stopScrolling();
            }, console.error);

        this.dragulaService.cloned.pipe(
            filter((value: [string, HTMLElement, HTMLElement, HTMLElement]) => {
                return value[0] === 'table-items';
            }),
            takeUntil(this.destroySubject$),
        ).subscribe(
            (value: [string, HTMLElement, HTMLElement, HTMLElement]) => {
                const elem = value[1];

                if (elem.id === 'dropped_items') {
                    const elemId: string = elem.getAttribute('col_id');
                    const colField: DraggableColumnModel = this.colFields.find(col => col.id === elemId);
                    if (colField) {
                        elem.classList.add('sis-form-builder__component--active');
                        elem.classList.add('sis-form-builder__component--col-' + colField.cellCount);
                    }
                } else {
                    elem.classList.add('sis-form-builder__component--new');
                    elem.classList.add('sis-form-builder__component--col-3');
                }

            }, console.error);

        const context = this;
        this.tableDragulaOptions = {
            direction: 'horizontal',
            copy     : function (el: HTMLElement, source: HTMLElement) {
                return el.id === 'new_field';
            },
            moves    : function (el: HTMLElement, source: HTMLElement, handle: HTMLElement) {
                if (el.id === 'new_field') {
                    const checkedSysName = el.getAttribute('col_systemName');
                    return context.colFields.findIndex(col => col.systemName === checkedSysName) === -1;
                }
                return true;
            },

        };

        this.dragulaService.setOptions('table-items', this.tableDragulaOptions);
    }

}
