import {takeUntil} from 'rxjs/operators';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {KbService, MetaField} from '@synisys/idm-kb-service-client-js';
import {
    CommunicationData,
    CommunicationKey,
    CommunicationService,
    ControlMetadata,
} from '@synisys/idm-dynamic-controls-metadata';
import {MetaFieldType} from '@synisys/idm-kb-service-client-js/src/model/meta-field';
import {TableDragulaOptions} from './table-dragula.options';
import {DraggableColumnModel} from './draggable-column.model';
import {FormData, FormService, FormType} from '@synisys/idm-dynamic-layout-interpreter';
import {Subject} from 'rxjs/Subject';
import './sis-table-settings.component.scss';
import {BaseSettings} from '@synisys/idm-ng-controls/app/controls/control-settings/sis-base-settings';

@Component({
    moduleId: module.id + '',
    selector: 'sis-table-settings',
    templateUrl: 'sis-table-settings.component.html',
})
@ControlMetadata({
    template: `<sis-table-settings [category]="%{category}" [formType]="%{formType}"
                            [fields]="%{fields}" [state]="%{state}" [currentLanguageId]="%{currentLanguageId}"
                            [languages]="%{languages}" [controlLabel]="%{controlLabel}">
                              </sis-table-settings>`,
})
export class SisTableSettings extends BaseSettings
    implements OnInit, OnDestroy {
    @Input()
    public category: string;
    @Input()
    public formType: FormType;
    @Input()
    public fields: {[s: string]: MetaField} = {};
    public subFieldModels: DraggableColumnModel[] = [];
    public tableDragulaOptions: TableDragulaOptions = new TableDragulaOptions();
    public metaFieldTypes = MetaFieldType;
    private _state: {[s: string]: object | boolean | string} = {};
    private _subEntityMetaFields: MetaField[] = [];
    private _selectedMetaField: MetaField;
    private _selectedFormData: FormData;
    private _subForms: FormData[] = [];
    private _isAddable: boolean;
    private _isDeletable: boolean;
    private destroySubject$: Subject<void> = new Subject<void>();

    constructor(
        private readonly kbService: KbService,
        private readonly communicationService: CommunicationService,
        private readonly formService: FormService
    ) {
        super(communicationService);
    }

    public get state(): {[s: string]: object | boolean | string} {
        return this._state;
    }

    @Input()
    public set state(state: {[s: string]: object | boolean | string}) {
        this._state = state;
    }

    get selectedMetaField(): MetaField {
        return this._selectedMetaField;
    }

    set selectedMetaField(value: MetaField) {
        this._selectedMetaField = value;
    }

    get subEntityMetaFields(): MetaField[] {
        return this._subEntityMetaFields;
    }

    set subEntityMetaFields(value: MetaField[]) {
        this._subEntityMetaFields = value;
    }

    get isAddable(): boolean {
        return this._isAddable;
    }

    set isAddable(value: boolean) {
        this._isAddable = value;
    }


    get isDeletable(): boolean {
        return this._isDeletable;
    }

    set isDeletable(value: boolean) {
        this._isDeletable = value;
    }

    set selectedFormData(value: FormData) {
        this._selectedFormData = value;
    }


    get selectedFormData(): FormData  {
        return this._selectedFormData;
    }

    get subForms(): FormData[] {
        return this._subForms;
    }

    set subForms(value: FormData[]) {
        this._subForms = value;
    }

    public ngOnInit(): void {
        this.kbService
            .getMetaFields(this.category)
            .pipe(takeUntil(this.destroySubject$))
            .subscribe((metaFields: MetaField[]) => {
                this._subEntityMetaFields = metaFields.filter(
                    metaField =>
                        metaField.getType() === MetaFieldType.SUB_ENTITY
                );
            }, console.error);

        this.prepareFields();
        this.prepareState();
    }

    public ngOnDestroy(): void {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }

    public categoryChange(selectedCategory: string): void {
        if (selectedCategory) {
            const selectedMetaField = this._subEntityMetaFields.find(
                item =>
                    item.getCompoundCategorySystemName() === selectedCategory
            );

            this.communicationService.parentSubject.next(
                new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
                    state: {},
                    fields: {
                        field: selectedMetaField,
                    },
                })
            );
            this.communicationService.peerSubject.next(
                new CommunicationData(CommunicationKey.SETTINGS_CHANGED, [])
            );

            this.kbService
                .getMetaFields(selectedCategory)
                .pipe(takeUntil(this.destroySubject$))
                .subscribe((metaFields: MetaField[]) => {
                    this.subFieldModels = metaFields.map(
                        field => new DraggableColumnModel(field)
                    );
                }, console.error);

            this.formService
                .allSubFormsByCategoryAndType(
                    this.category,
                    selectedCategory,
                    this.formType
                )
                .pipe(takeUntil(this.destroySubject$))
                .subscribe((data: FormData[]) => {
                    this.subForms = data;
                }, console.error);
        }
    }

    public subFormChange(formData: FormData): void {
        if (formData) {
            this.communicationService.parentSubject.next(
                new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
                    state: {
                        subFormKey: formData.model.formName,
                        formId: formData.id,
                    },
                })
            );
        }
    }

    public changeAddableProperties(value: boolean): void {
        this.isAddable = value;
        this.communicationService.parentSubject.next(
            new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
                state: {
                    isAddable: this.isAddable,
                },
            })
        );
    }

    public changeDeletableProperties(value: boolean): void {
        this.isDeletable = value;
        this.communicationService.parentSubject.next(
            new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
                state: {
                    isDeletable: this.isDeletable,
                },
            })
        );
    }

    public trackByFieldModelsIds(
        index: number,
        subFieldModel: DraggableColumnModel
    ): string {
        return subFieldModel.id;
    }

    public trackByFormsIds(index: number, selectedFormData: FormData): number {
        return selectedFormData.id;
    }

    public isEditForm(): boolean {
        return this.formType === FormType.EDIT;
    }

    private prepareFields(): void {
        if (this.fields.field) {
            this.selectedMetaField = this.fields.field;

            if (this.selectedMetaField) {
                const formId = this._state['formId'] as null;
                this.formService
                    .allSubFormsByCategoryAndType(
                        this.category,
                        this.selectedMetaField.getCompoundCategorySystemName(),
                        this.formType
                    )
                    .pipe(takeUntil(this.destroySubject$))
                    .subscribe((data: FormData[]) => {
                        this.subForms = data;
                        if (formId) {
                            this.selectedFormData = this.subForms.find(
                                sub => sub.id === formId
                            );
                        }
                    }, console.error);

                this.kbService
                    .getMetaFields(
                        this.selectedMetaField.getCompoundCategorySystemName()
                    )
                    .pipe(takeUntil(this.destroySubject$))
                    .subscribe((metaFields: MetaField[]) => {
                        this.subFieldModels = metaFields.map(
                            field => new DraggableColumnModel(field)
                        );
                    }, console.error);
            }
        }
    }

    private prepareState(): void {
        if (this._state) {
            this.isAddable = this._state['isAddable'] as boolean;
            this.isDeletable = this._state['isDeletable'] as boolean;

            if (this._state.colSystemNames) {
                (this._state.colSystemNames as string[]).forEach(
                    (col: string) => {
                        const sub = this.subFieldModels.find(
                            item => item.systemName === col
                        );
                        if (sub) {
                            sub.disabled = true;
                        }
                    }
                );
            }
        }
    }
}
