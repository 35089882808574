import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActionDto} from '@synisys/idm-workflow-service-client-js';
import {Entity} from '@synisys/idm-de-core-frontend';
import {FormActions} from '../../models';
import {ExportTemplate} from '../header-information';
import {UserHelpItem} from '../../models/user-help-item.model';

@Component({
    moduleId: module.id + '',
    selector: 'sis-header-view-form',
    templateUrl: 'sis-header-view-form.component.html',
})
export class SisHeaderViewFormComponent {
    @Input()
    public id: string;

    @Input()
    public entity: Entity;

    @Input()
    public categoryName: string;

    @Input()
    public userHelpItems: UserHelpItem[];

    @Input()
    public workflowActions: ActionDto[] = [];

    @Input()
    public currentLanguageId: number;

    @Input()
    public labelMessage: string;

    @Input()
    public hasEditPermission: boolean;

    @Input()
    public withDeleteButton: boolean;

    @Input()
    public withEditButton = true;

    @Input()
    public isReadonly = false;

    @Input()
    public form: object;

    @Input()
    public formActions: FormActions;

    @Input()
    public exportTemplates: ExportTemplate[];

    @Output()
    public onWfSelect: EventEmitter<number> = new EventEmitter<number>();

    @Output()
    public editClick: EventEmitter<void> = new EventEmitter<void>();

    public hasWorkflow(): boolean {
        return (
            this.workflowActions.length > 0 ||
            (this.entity === null && this.isReadonly)
        );
    }

    public isEditable(): boolean {
        return (
            this.hasEditPermission || (this.entity === null && this.isReadonly)
        );
    }
}
