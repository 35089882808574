import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    KbService,
    MetaField,
    MetaFieldType,
} from '@synisys/idm-kb-service-client-js';
import {Entity} from '@synisys/idm-de-core-frontend';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {BaseTable, FormActions} from '../../models';
import {Classifier} from '@synisys/idm-classifier-service-client-js';
import {DeService, SubEntity} from '@synisys/idm-de-service-client-js';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {ExtractionService} from '../../services';
import {ValidationService} from '@synisys/idm-validation-calculation-service-client-js';
import {MatDialog} from '@angular/material';
import {SisTableSettings} from './sis-table-settings.component';
import {SisTableBuilder} from './sis-table-builder.component';
import {zip} from 'rxjs/observable/zip';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {takeUntil} from 'rxjs/operators';
import './sis-table.component.scss';
import {FormType} from '@synisys/idm-dynamic-layout-interpreter';

@Component({
               moduleId   : module.id + '',
               selector   : 'sis-table',
               templateUrl: 'sis-table.component.html',
           })
@ControlMetadata({
                     template : `
        <sis-table [id]="'%{id}'" [categoryName]="'%{field-category}'" [actions]="actions" [form]="form"
                   [fieldName]="'%{field-name}'" [mainCategory]="category"
                   [subFormKey]="%{subFormKey}" [colSystemNames]="%{colSystemNames}" [formType]="formType"
                   [cellCounts]="%{cellCounts}" [formId]="%{formId}" [isReadonly]="%{isReadonly}"
                   [isDeletable]="%{isDeletable}" [isAddable]="%{isAddable}" [rowItems]="%{field}">
        </sis-table>
    `,
                     cellCount: 12,
                     settings : {
                         main   : SisTableSettings,
                         central: SisTableBuilder,
                     },
                     groups   : [{identity: 'input', name: 'Input Elements'}],
                     iconInfo : 'fa-dot-circle-o',
                     name     : 'Table',
                     nameKey  : 'de.controls.table.title',
                 })
export class SisTable extends BaseTable implements OnInit, OnDestroy {
    @Input()
    public categoryName: string;
    @Input()
    public rowItems: Entity[];
    @Input()
    public actions: FormActions;
    @Input()
    public isDeletable: boolean;
    @Input()
    public isAddable: boolean;
    @Input()
    public colSystemNames: string[] | undefined;
    @Input()
    public cellCounts: number[] | undefined;
    @Input()
    public subFormKey: string;
    @Input()
    public formId: number;
    @Input()
    public form: object;
    @Input()
    public id: string;
    @Input()
    public formType: FormType;
    @Input()
    public isReadonly: boolean;
    @Input()
    public fieldName: string;
    @Input()
    public mainCategory: string;
    @Input()
    public perColumnAlignment: object;

    public messageId = 'de_add';

    // Expose enums to use in template.
    public metaFieldType = MetaFieldType;

    private _allMetaFields: MetaField[];

    constructor(
        private kbService: KbService,
        private currentLanguageProvider: CurrentLanguageProvider,
        private deService: DeService,
        private extractionService: ExtractionService,
        private validationService: ValidationService,
        public dialog: MatDialog
    ) {
        super();
    }

    public ngOnInit(): void {
        const kbData$ = this.kbService.getMetaFields(this.categoryName);
        const currentLanguage$ = this.currentLanguageProvider.getCurrentLanguage();
        const requiredMetaFields$ = this.validationService.getCategoryRequiredMetaFieldIds(
            this.categoryName
        );
        zip(kbData$, currentLanguage$, requiredMetaFields$)
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(
                data => {
                    this.digOutColumnsMetaFields(data[0]);
                    this.handleColumnAlignments();
                    this.currentLanguage = data[1];
                    this.handleHeader(data[2]);
                    this.isReady = true;
                },
                err => console.error(err)
            );
    }

    public handleColumnAlignments() {
        if (this.columnMetaFields) {
            this.columnMetaFields.forEach((col: MetaField) => {
                this.handleAlignments(col, this.perColumnAlignment);
            });
        }
    }

    public openAddPopup() {
        this.deService
            .createBlankSubEntity(this.categoryName)
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(
                (subEntity: SubEntity) => {
                    subEntity.setId(this.getUniqueIdForSubEntity());
                    this.actions.openPopup(
                        this.form,
                        this.categoryName,
                        this.subFormKey,
                        subEntity,
                        this.formId
                    );
                },
                err => console.error(err)
            );
    }

    public inlineAdd() {
        this.deService
            .createBlankSubEntity(this.categoryName)
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(
                (subEntity: SubEntity) => {
                    subEntity.setId(this.getUniqueIdForSubEntity());
                    this.rowItems.push(subEntity);
                },
                err => console.error(err)
            );
    }

    public openEditPopup(rowItem: Entity) {
        this.deService
            .createBlankSubEntity(this.categoryName)
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(
                (subEntity: SubEntity) => {
                    this._allMetaFields.forEach(metaField => {
                        subEntity.getProperty(
                            metaField.getSystemName()
                        ).value = rowItem.getProperty(
                            metaField.getSystemName()
                        ).value;
                    });
                    this.actions.openEditPopup(
                        this.form,
                        this.categoryName,
                        this.subFormKey,
                        subEntity,
                        this.formId
                    );
                },
                error => console.error(error)
            );
    }

    public getCellWithPercentage(index: number) {
        if (this.cellCounts && this.cellCounts.length > index) {
            return (this.cellCounts[index] / 12) * 100;
        }
        return 33;
    }

    public isViewForm(): boolean {
        return this.formType === FormType.VIEW;
    }

    public isEditForm(): boolean {
        return this.formType === FormType.EDIT;
    }

    public getColContent(
        rowItem: Entity,
        metaField: MetaField
    ): Observable<object | string> {
        const languageId = this.currentLanguage.getId();
        const compoundCategoryName = metaField.getCompoundCategorySystemName();

        if (metaField.getType() === MetaFieldType.MULTILINGUAL_STRING) {
            return of(
                rowItem
                    .getProperty<MultilingualString>(metaField.getSystemName())
                    .value.getValue(languageId)
            );
        } else if (metaField.getType() === MetaFieldType.CLASSIFIER) {
            return this.extractionService.extractNameFromClassifierCached(
                rowItem.getProperty<Classifier>(metaField.getSystemName())
                    .value,
                compoundCategoryName,
                languageId
            );
        } else if (metaField.getType() === MetaFieldType.MULTI_SELECT) {
            return this.extractionService.extractNameFromMultiSelectCached(
                rowItem.getProperty<Classifier[]>(metaField.getSystemName())
                    .value,
                compoundCategoryName,
                languageId
            );
        } else if (metaField.getType() === MetaFieldType.MAIN_ENTITY) {
            return this.extractionService.extractNameFromEntityCached(
                rowItem.getProperty<Entity>(metaField.getSystemName()).value,
                compoundCategoryName,
                languageId
            );
        }
        return of(rowItem.getProperty(metaField.getSystemName()).value);
    }

    public trackByRowItemsIds(index: number, rowItem: Entity) {
        return rowItem.getId();
    }

    public trackByMetaFieldsIds(
        index: number,
        columnMetaField: MetaField
    ): string {
        return columnMetaField.getMetaFieldId().getSystemName();
    }

    private digOutColumnsMetaFields(metaFields: MetaField[]) {
        this._allMetaFields = metaFields;
        this.columnMetaFields = [];
        if (this.colSystemNames) {
            this.columnMetaFields = this.colSystemNames.map(colName =>
                                                                metaFields.find(meta => meta.getSystemName() == colName)
            );
        }
    }
}
