import {NgModule} from '@angular/core';
import {AuditService, DataService, HttpAuditService, HttpDataService} from '@synisys/idm-audit-service-client-js';
import {SubwayMapComponent} from './sis-subway-map.component';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../../material.module';
import {SisTranslateModule} from '@synisys/idm-frontend-shared';
import {ActionService, HttpActionService} from "@synisys/idm-workflow-service-client-js";

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        MaterialModule,
        SisTranslateModule
    ],
    declarations: [
        SubwayMapComponent
    ],
    exports: [
        SubwayMapComponent
    ],
    providers: [
        {provide: DataService, useClass: HttpDataService},
        {provide: AuditService, useClass: HttpAuditService},
        {provide: ActionService, useClass: HttpActionService},
    ]
})
export class SubwayMapModule {

}
