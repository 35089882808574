import {
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {Entity} from '@synisys/idm-de-core-frontend';
import {
    AuditItem,
    AuditService,
    FilterAuditItem,
    Order,
} from '@synisys/idm-audit-service-client-js';
import {KbHttpService} from '@synisys/idm-kb-service-client-js';
import {
    Classifier,
    ClassifierService,
    ClassifiersResponse,
} from '@synisys/idm-classifier-service-client-js';
import {Observable} from 'rxjs/Observable';
import {map, switchMap, takeUntil} from 'rxjs/operators';
import {SubwayModel} from './model/subway.model';
import {FormattingService} from '../../services';
import {
    ActionDto,
    ActionService,
} from '@synisys/idm-workflow-service-client-js';
import {MessageService} from '@synisys/idm-message-language-service-client-js';
import {Subscription} from 'rxjs/Subscription';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {
    Language,
    LanguageDirection,
    MultilingualString,
} from '@synisys/idm-crosscutting-concepts-frontend';
import './sis-subway-map.component.scss';
import {Subject} from 'rxjs/Subject';
import {
    categoryStoreManager,
    fieldStoreManager,
    KbState,
    MetaCategory,
    MetaField,
} from '@synisys/skynet-store-kb-api';
import {Store} from '@ngrx/store';

/**
 * @author Kadmos.Balkhchyan on 12/16/2019
 */
@Component({
    moduleId: module.id + '',
    selector: 'sis-subway-map',
    templateUrl: 'sis-subway-map.component.html',
})
@ControlMetadata({
    template: `
             <sis-subway-map [entity]="entity"
                             [categoryName]="category"
                             [currentLanguageId]="currentLanguageId"
                             [actionNotifier]="actionNotifier"
                             [containerType]="containerType">
             </sis-subway-map>`,
    isSubwayMap: true,
    name: 'Subway Map',
    nameKey: 'de.controls.subway_map.title',
})
export class SubwayMapComponent implements OnInit, OnDestroy {
    @Input()
    public entity: Entity;

    @Input()
    public categoryName: string;

    @Input()
    public currentLanguageId: number;

    @Input()
    public actionNotifier: Observable<{key: number}>;

    @Input()
    public containerType: string | undefined;

    public readonly ACTION_WORKFLOW = 'audit_service.action.workflow';
    public readonly ACTION_CREATE = 'audit_service.action.create';
    public readonly USER_SYSTEM_NAME = 'User';
    public readonly ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
    public readonly ONE_HOUR_IN_MS = 60 * 60 * 1000;
    public readonly ONE_MINUTE_IN_MS = 60 * 1000;
    public readonly MESSAGE_KEYS = [
        'subway_map.wf_action_date_message',
        'subway_map.wf_action_by_message',
        'subway_map.wf_passed_time_message',
        'subway_map.duration_days_message.short',
        'subway_map.duration_hours_message.short',
        'subway_map.duration_minutes_message.short',
        'subway_map.wf_action_name_message',
    ];

    public isRtl: boolean;

    public contFixed: boolean;

    public showPrevButton: boolean;
    public showNextButton: boolean;

    // For stopping transition when resizing window
    public noTransition: boolean;
    public leftDiff: number;
    public rightDiff: number;

    public translateSize = 284;
    public moveSize = 0;
    public initialSpace = 40;

    public wfStateData: SubwayModel[];

    public futureStateData: Observable<string>[];

    public usedMessages: Map<string, string>;

    @ViewChild('subwayContainer')
    public subwayContainer: ElementRef;

    @ViewChild('subwayMap')
    public subwayMap: ElementRef;

    protected destroy$: Subject<boolean> = new Subject<boolean>();

    private wfSubscription: Subscription;

    private currentState: number;

    constructor(
        private auditService: AuditService,
        private classifierService: ClassifierService,
        private formattingService: FormattingService,
        private actionService: ActionService,
        private messageService: MessageService,
        private currentLanguageProvider: CurrentLanguageProvider,
        private store: Store<KbState>
    ) {}

    public ngOnInit(): void {
        if (this.entity && this.entity.getInstanceId()) {
            this.loadWfStateAuditData();
        }
        this.initActionNotifier();
        this.loadCurrentLanguage();
    }

    public ngOnDestroy(): void {
        this.resetWfSubscription();
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    public subwayInit(): void {
        const childReallySize =
            this.subwayMap.nativeElement.offsetWidth + this.initialSpace;

        if (childReallySize > this.subwayContainer.nativeElement.offsetWidth) {
            this.contFixed = true;
            this.showPrevButton = true;
        }
    }

    public subwayResize(): void {
        this.noTransition = false;

        if (!this.subwayMap || !this.subwayContainer) {
            return;
        }
        const childElement = this.subwayMap.nativeElement.getBoundingClientRect();
        const parentElement = this.subwayContainer.nativeElement.getBoundingClientRect();
        this.leftDiff =
            childElement.left - this.initialSpace - parentElement.left;
        this.rightDiff =
            childElement.right + this.initialSpace - parentElement.right;

        const childReallySize =
            this.subwayMap.nativeElement.offsetWidth + this.initialSpace;

        if (
            childReallySize > this.subwayContainer.nativeElement.offsetWidth &&
            !this.showNextButton
        ) {
            this.contFixed = true;
            this.showPrevButton = true;
        } else if (
            childReallySize > this.subwayContainer.nativeElement.offsetWidth &&
            this.showNextButton
        ) {
            this.showPrevButton = true;

            if (this.isRtl) {
                if (this.rightDiff <= 0) {
                    this.moveSize -= this.rightDiff;
                    this.showPrevButton = false;
                    this.noTransition = true;
                }
            } else {
                if (this.leftDiff >= 0) {
                    this.moveSize -= this.leftDiff;
                    this.showPrevButton = false;
                    this.noTransition = true;
                }
            }
        } else {
            this.contFixed = false;
            this.showPrevButton = false;
            this.showNextButton = false;
            this.moveSize = 0;
        }
    }

    public prevClicked(): void {
        this.showNextButton = true;
        this.noTransition = false;
        if (this.isRtl) {
            this.moveSize += -this.translateSize;
        } else {
            this.moveSize += this.translateSize;
        }

        const childElement = this.subwayMap.nativeElement.getBoundingClientRect();
        const parentElement = this.subwayContainer.nativeElement.getBoundingClientRect();

        /**
         * if subway map moved to the left hiding prev button and moving transition to the end
         */
        if (this.isRtl) {
            if (
                childElement.right - this.translateSize <=
                parentElement.right
            ) {
                this.showPrevButton = false;
                this.moveSize =
                    this.subwayContainer.nativeElement.offsetWidth -
                    this.subwayMap.nativeElement.offsetWidth -
                    this.initialSpace;
            }
        } else {
            if (childElement.left + this.translateSize >= parentElement.left) {
                this.showPrevButton = false;
                this.moveSize =
                    this.subwayMap.nativeElement.offsetWidth -
                    this.subwayContainer.nativeElement.offsetWidth +
                    this.initialSpace;
            }
        }
    }

    public nextClicked(): void {
        this.noTransition = false;
        this.showPrevButton = true;

        if (this.isRtl) {
            this.moveSize += this.translateSize;

            if (this.moveSize >= 0) {
                this.showNextButton = false;
                this.moveSize = 0;
            }
        } else {
            this.moveSize -= this.translateSize;

            if (this.moveSize <= 0) {
                this.showNextButton = false;
                this.moveSize = 0;
            }
        }
    }

    @HostListener('window:resize')
    public onResize(): void {
        this.subwayResize();
    }

    private loadCurrentLanguage(): void {
        this.currentLanguageProvider.getCurrentLanguage().subscribe(
            (language: Language) => {
                this.isRtl = language.getDirection() === LanguageDirection.RTL;
            },
            err => console.error(err)
        );
    }

    private resetWfSubscription(): void {
        if (this.wfSubscription) {
            this.wfSubscription.unsubscribe();
            this.wfSubscription = undefined;
        }
    }

    private loadWfStateAuditData(): void {
        this.resetWfSubscription();

        this.wfSubscription = Observable.zip(
            this.getWorkflowStateMetaField(this.categoryName),
            categoryStoreManager(this.containerType).selectOne(
                this.store,
                this.categoryName
            )
        )
            .pipe(
                switchMap(this.loadWfActionsAndAuditData),
                switchMap(this.loadClassifiers),
                map(this.transformData)
            )
            .subscribe(
                ([wfStateData, futureStateData]: [
                    SubwayModel[],
                    Observable<string>[]
                ]) => {
                    this.wfStateData = wfStateData;
                    this.futureStateData = futureStateData;

                    setTimeout(() => {
                        this.subwayInit();
                    }, 0);
                },
                console.error
            );
    }

    private loadWfActionsAndAuditData = ([metaField, metaCategory]: [
        MetaField,
        MetaCategory
    ]) => {
        return Observable.zip(
            this.loadCreateActionAuditData(),
            this.loadWfActionAuditData(),
            Observable.of(metaField),
            this.actionService.getActionsByProcessId(metaCategory.wfProcessId)
        );
    };

    private loadCreateActionAuditData = () => {
        const initialFilterAuditItem: FilterAuditItem = new FilterAuditItem(
            this.entity.getInstanceId(),
            this.categoryName,
            undefined,
            this.ACTION_CREATE,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            Order.ASC
        );

        return this.auditService.getAuditItems(initialFilterAuditItem);
    };

    private loadWfActionAuditData = () => {
        const filterAuditItem: FilterAuditItem = new FilterAuditItem(
            this.entity.getInstanceId(),
            this.categoryName,
            undefined,
            this.ACTION_WORKFLOW,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            Order.ASC
        );

        return this.auditService.getAuditItems(filterAuditItem);
    };

    private loadClassifiers = ([
        initialAuditItems,
        auditItems,
        metaField,
        actions,
    ]: [AuditItem[], AuditItem[], MetaField, ActionDto[]]) => {
        const userIds: Set<number> = new Set<number>();
        const wfStateIds: Set<number> = new Set<number>();
        const allAuditItems = initialAuditItems.concat(...auditItems);

        allAuditItems.forEach((auditItem: AuditItem) => {
            userIds.add(auditItem.userId);
            wfStateIds.add(auditItem.data[metaField.metaFieldId.systemName]);
        });

        if (allAuditItems.length === 0) {
            return Observable.of();
        }

        this.currentState =
            allAuditItems[allAuditItems.length - 1].data[
                metaField.metaFieldId.systemName
            ];
        const entityWfState = (this.entity.getProperty(
            metaField.metaFieldId.systemName
        ).value as Classifier).getId();
        if (this.entity && entityWfState !== this.currentState) {
            this.currentState = entityWfState;
        }

        wfStateIds.add(this.currentState);

        const futureActions = actions.filter((action: ActionDto) => {
            const isFiltered = action.getInitialStateId() === this.currentState;
            if (isFiltered) {
                wfStateIds.add(action.getTargetStateId());
            }
            return isFiltered;
        });

        return Observable.zip(
            Observable.of(allAuditItems),
            Observable.of(metaField),
            Observable.of(futureActions),
            Observable.of(actions),
            this.classifierService.loadClassifiersByIds(
                this.USER_SYSTEM_NAME,
                Array.from(userIds)
            ),
            this.classifierService.loadClassifiersByIds(
                metaField.workflowStateMetaCategoryId.systemName,
                Array.from(wfStateIds)
            ),
            Observable.from(
                this.messageService.getMessages(
                    this.currentLanguageId,
                    this.MESSAGE_KEYS
                )
            )
        );
    };

    private transformData = ([
        auditItems,
        metaField,
        futureActions,
        actions,
        users,
        wfStates,
        messages,
    ]: [
        AuditItem[],
        MetaField,
        ActionDto[],
        ActionDto[],
        ClassifiersResponse,
        ClassifiersResponse,
        Map<string, string>
    ]) => {
        const usersMap: Map<number, Observable<string>> = new Map<
            number,
            Observable<string>
        >();
        const wfStatesMap: Map<number, Observable<string>> = new Map<
            number,
            Observable<string>
        >();
        this.usedMessages = messages;

        users.getData().forEach((user: Classifier) => {
            usersMap.set(
                user.getId(),
                this.classifierService.getEntityName(
                    this.USER_SYSTEM_NAME,
                    user
                )
            );
        });

        wfStates.getData().forEach((wfState: Classifier) => {
            wfStatesMap.set(
                wfState.getId(),
                this.classifierService.getEntityName(
                    metaField.workflowStateMetaCategoryId.systemName,
                    wfState
                )
            );
        });

        const lastActionFieldSystemName =
            KbHttpService.WORKFLOW_ACTION_ID_SYSTEM_NAME;

        const actionsNamesMap = new Map<number, MultilingualString>();
        actions.forEach((action: ActionDto) => {
            actionsNamesMap.set(action.getId(), action.getName());
        });

        const subwayData = auditItems.map(
            (auditItem: AuditItem, index: number) => {
                let duration: number;
                if (index < auditItems.length - 1) {
                    duration =
                        auditItems[index + 1].modifiedDate -
                        auditItem.modifiedDate;
                } else {
                    duration = new Date().valueOf() - auditItem.modifiedDate;
                }

                const lastActionId = auditItem.data[lastActionFieldSystemName];

                return new SubwayModel(
                    wfStatesMap.get(
                        auditItem.data[metaField.metaFieldId.systemName]
                    ),
                    usersMap.get(auditItem.userId),
                    this.formattingService.formatDateTime(
                        auditItem.modifiedDate
                    ),
                    this.formatDuration(duration),
                    lastActionId ? actionsNamesMap.get(lastActionId) : undefined
                );
            }
        );

        if (
            auditItems[auditItems.length - 1].data[
                metaField.metaFieldId.systemName
            ] !== this.currentState
        ) {
            const actionId = Number(
                this.entity.getProperty(lastActionFieldSystemName).value
            );
            subwayData.push(
                new SubwayModel(
                    wfStatesMap.get(this.currentState),
                    Observable.of(''),
                    Observable.of(''),
                    '',
                    actionId ? actionsNamesMap.get(actionId) : undefined
                )
            );
        }

        return [
            subwayData,
            futureActions.map((action: ActionDto) => {
                return wfStatesMap.get(action.getTargetStateId());
            }),
        ];
    };

    private formatDuration(duration: number): string {
        const days = Math.floor(duration / this.ONE_DAY_IN_MS);
        const hours = Math.floor(
            (duration - days * this.ONE_DAY_IN_MS) / this.ONE_HOUR_IN_MS
        );
        // round to upper number for including seconds
        const minutes = Math.round(
            (duration -
                days * this.ONE_DAY_IN_MS -
                hours * this.ONE_HOUR_IN_MS) /
                this.ONE_MINUTE_IN_MS
        );

        let formattedDuration = '';
        if (days !== 0) {
            formattedDuration = formattedDuration.concat(
                `${days} ${this.usedMessages.get(this.MESSAGE_KEYS[3])} `
            );
        }
        if (hours !== 0) {
            formattedDuration = formattedDuration.concat(
                `${hours} ${this.usedMessages.get(this.MESSAGE_KEYS[4])} `
            );
        }
        if (minutes !== 0) {
            formattedDuration = formattedDuration.concat(
                `${minutes} ${this.usedMessages.get(this.MESSAGE_KEYS[5])}`
            );
        }

        if (formattedDuration.length === 0) {
            formattedDuration = `1 ${this.usedMessages.get(
                this.MESSAGE_KEYS[5]
            )}`;
        }

        return formattedDuration;
    }

    private initActionNotifier(): void {
        if (this.actionNotifier !== undefined) {
            this.actionNotifier
                .pipe(takeUntil(this.destroy$))
                .subscribe(saveEventData => {
                    if (
                        this.entity &&
                        this.entity.getInstanceId() &&
                        (saveEventData.key === 3 || saveEventData.key === 1)
                    ) {
                        this.loadWfStateAuditData();
                    }
                }, console.error);
        }
    }

    private getWorkflowStateMetaField(
        categorySystemName: string
    ): Observable<MetaField> {
        return fieldStoreManager(this.containerType)
            .selectAll(this.store, categorySystemName)
            .pipe(
                map(metaFields =>
                    metaFields.find(
                        metaField =>
                            metaField.metaFieldType === 'WORKFLOW_STATE'
                    )
                )
            );
    }
}
