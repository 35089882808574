export class TableDragulaOptions{

  public TABLE_COLUMN_OPTIONS = {
    direction: 'horizontal',
    copy     : function (el: HTMLElement, source: HTMLElement) {
      return el.id === "new_field";
    },

    moves    : function (el: HTMLElement, source: HTMLElement, handle: HTMLElement){
      return true;
    }
  };
}
