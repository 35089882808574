import {Observable} from 'rxjs/Observable';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';

export class SubwayModel {
    constructor(
        public stateName: Observable<string>,
        public userName: Observable<string>,
        public modifiedDate: Observable<string>,
        public passedTime: string,
        public actionName: MultilingualString
    ) {}
}
