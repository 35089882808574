import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';

import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {Language, MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {LanguageService} from '@synisys/idm-message-language-service-client-js';
import {multilingualToString, stringToMultilingual} from '../../utilities';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import './sis-tooltip-settings.component.scss';

@Component({
             moduleId   : module.id + '',
             selector   : 'sis-tooltip-settings',
             templateUrl: 'sis-tooltip-settings.component.html',
           })
@ControlMetadata({
                   template: `<sis-tooltip-settings
                                    [settings]="%{tooltipSettings}">
                              </sis-tooltip-settings>`,
                 })
export class TooltipSettingsComponent implements OnInit, AfterViewInit, AfterViewChecked {

  public isReady = false;
  @Input()
  private settings: TooltipSettings;

  public languageSettings$: Observable<{languages: Language[], currentLanguageId: number}> | undefined;

  @ViewChild('isWithTooltip')
  private isWithTooltipCheckbox: ControlValueAccessor;

  @ViewChild('caption')
  private captionComponent: ControlValueAccessor;

  @ViewChild('content')
  private contentComponent: ControlValueAccessor;

  @Output()
  private settingsChange: EventEmitter<TooltipSettings> = new EventEmitter<TooltipSettings>();

  constructor(private languageService: LanguageService,
              private currentLanguageProvider: CurrentLanguageProvider) {
  }

  public ngOnInit(): void {
    const languages$ = this.languageService.getInputLanguages();
    const currentLanguage$ = this.currentLanguageProvider.getCurrentLanguage();

    this.languageSettings$ = combineLatest(languages$, currentLanguage$)
      .pipe(
        map((data: [Language[], Language]) => {
          if (this.settings === undefined) {
            this.settings = TooltipSettings.createEmptyFrom(data[0]);
            this.settingsChange.emit(this.settings);
          }
          return {
            languages        : data[0],
            currentLanguageId: data[1].getId(),
          };
        }),
        catchError(err => {
          console.error(err);
          return of(undefined);
        }),
      );
  }

  public ngAfterViewInit(): void {
    this.isWithTooltipCheckbox.registerOnChange((value: boolean) => {
      this.settings.isWithTooltip = value;
      this.settingsChange.emit(value ? this.settings : new TooltipSettings(false));
    });
  }

  public ngAfterViewChecked(): void {
    if (this.settings.isWithTooltip) {
      this.captionComponent === undefined
      || this.captionComponent.registerOnChange((value: MultilingualString) => {
        this.settings.caption = value;
        this.settingsChange.emit(this.settings);
      });
      this.contentComponent === undefined
      || this.contentComponent.registerOnChange((value: MultilingualString) => {
        this.settings.content = value;
        this.settingsChange.emit(this.settings);
      });
    }
  }
}

/*tslint:disable:max-classes-per-file*/
/**
 * @deprecated use TooltipSettings from ng-controls
 */
export class TooltipSettings {

  public static createEmptyFrom(languages: Language[]) {
    const languageIds = languages.map(language => language.getId());
    const caption = MultilingualString.newBuilder().withValueForLanguages(languageIds, '').build();
    const content = MultilingualString.newBuilder().withValueForLanguages(languageIds, '').build();
    return new TooltipSettings(false, caption, content);
  }

  public static fromJsonString(json: string) {
    const obj: object = JSON.parse(json);
    return new TooltipSettings(obj['isWithTooltip'],
                               stringToMultilingual(obj['caption']),
                               stringToMultilingual(obj['content']));
  }

  constructor(public isWithTooltip: boolean,
              public caption?: MultilingualString | undefined,
              public content?: MultilingualString | undefined) {
  }

  public toJsonString(): string {
    return JSON.stringify({
                            isWithTooltip: this.isWithTooltip,
                            caption      : multilingualToString(this.caption),
                            content      : multilingualToString(this.content)
                          });
  }

}
