/**
 * @author Vahagn Lazyan.
 * @since 2.2.0
 */
import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';

import {PopupWindow} from '@synisys/idm-ng-controls';

@Component({
             moduleId   : module.id + '',
             selector   : 'warning-popup',
             templateUrl: 'warning-popup.component.html',
           })
export class WarningPopupComponent extends PopupWindow {

  /**
   * Display message key.
   * Should be Message service's valid message key.
   * @type {string}
   */
  @Input()
  public displayMessageKey: string;

  @Output()
  public onOkClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public onNoClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {
    super(elementRef);
    this.width = 400;
    this.height = 'auto';
  }

  /**
   * To open popup from another component.
   */
  public openPopup(): void {
    this.open();
  }

  /**
   * To close popup from another component.
   */
  public closePopup(): void {
    this.close();
  }

  public onNo() {
    this.onNoClicked.emit();
  }

  public onOk() {
    this.onOkClicked.emit();
  }
}
